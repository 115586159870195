<template>
  <!-- 视频配置 -->
  <div class="aisle-video-page x-background-color x-box-shadow">
    <x-table
      new-style
      :no-data-text="CA('aisleVideo_check') ? '暂无数据' : '暂无数据查询权限'"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
      :config="config"
      @search="search"
      @add="add"
      @page-change="pageChange"
      @page-size-change="pageSizeChange"
    >
      <template #footer>
        <div class="footer">
          <Page
            :current="page.pageNo"
            :page-size="page.pageSize"
            :total="page.total"
            :show-elevator="true"
            :show-total="true"
            @on-change="pageChange"
          ></Page>
        </div>
      </template>
    </x-table>

    <Modal
      v-model="modal.show"
      :title="modal.title"
      :width="420"
      @on-visible-change="modalShow"
    >
      <Form ref="form" :model="form" :rules="rules" :label-width="90">
        <FormItem label="设备名称" prop="name">
          <Input v-model="form.name"></Input>
        </FormItem>
        <FormItem label="基地场景" prop="farmId">
          <!-- <Cascader
            ref="cascader"
            :data="farmScene"
            v-model="form.area"
            @on-change="cascaderChange"
          ></Cascader> -->
          <Select v-model="form.farmId">
            <Option
              :value="item.id"
              v-for="(item, index) in farmScene"
              :key="index"
              >{{ item.name }}</Option
            >
          </Select>
        </FormItem>
        <FormItem label="设备码" prop="httpUrl">
          <Input v-model="form.httpUrl"></Input>
        </FormItem>
        <!-- <Row>
          <Col span="11">
            <FormItem label="设备位置" prop="address">
              <Input v-model="form.address" disabled>
                <Icon
                  type="md-map"
                  slot="append"
                  style="cursor: pointer"
                  @click="mapModelShow"
                />
              </Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="设备类型" prop="device">
              <Select v-model="form.device" clearable>
                <Option
                  v-for="(item, index) in deviceTypeArr"
                  :value="`${item.num}/${item.name}`"
                  :key="index"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="监控类型" prop="modelType">
              <Select v-model="form.modelType" clearable>
                <Option :value="1">枪机</Option>
                <Option :value="2">球机</Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="IP/域名" prop="linkIp">
              <Input v-model="form.linkIp"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row
          v-if="form.device && form.device.split('/')[0] == 6 ? false : true"
        >
          <Col span="11">
            <FormItem label="端口号" prop="devicePort">
              <Input v-model="form.devicePort"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="通道号" prop="channelNo">
              <Input v-model="form.channelNo"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem
              :label="
                form.device && form.device.split('/')[0] == 6
                  ? '应用key'
                  : '设备登录名'
              "
              prop="loginUser"
            >
              <Input v-model="form.loginUser"></Input>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem
              :label="
                form.device && form.device.split('/')[0] == 6
                  ? '应用Secret'
                  : '设备登录密码'
              "
              prop="loginPwd"
            >
              <Input v-model="form.loginPwd"></Input>
            </FormItem>
          </Col>
        </Row>
        <Row
          v-if="form.device && form.device.split('/')[0] == 6 ? false : true"
        >
          <Col span="11">
            <FormItem label="抓拍分辨率" prop="resolvingPower">
              <Select v-model="form.resolvingPower" clearable>
                <Option
                  v-for="(item, index) in photoTypeArr"
                  :value="`${item.num}/${item.name}`"
                  :key="index"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="抓拍质量" prop="quality">
              <Select v-model="form.quality" clearable>
                <Option
                  v-for="(item, index) in qualityArr"
                  :value="`${item.num}/${item.name}`"
                  :key="index"
                  >{{ item.name }}</Option
                >
              </Select>
            </FormItem>
          </Col>
        </Row>
        <Row>
          <Col span="11">
            <FormItem label="视频封面" prop="imgurl">
              <upload-image
                ref="upload"
                :max="1"
                v-model="form.imgurl"
              ></upload-image>
            </FormItem>
          </Col>
          <Col span="11" offset="1">
            <FormItem label="备注" prop="remark">
              <Input v-model="form.remark" type="textarea" :rows="3"></Input>
            </FormItem>
          </Col>
        </Row> -->
      </Form>
      <p slot="footer" style="text-align: center">
        <Button type="primary" :loading="modal.submitLoading" @click="submit"
          >提交</Button
        >
        <Button @click="() => (modal.show = false)">取消</Button>
      </p>
    </Modal>

    <Modal v-model="mapModel" fullscreen class="secModMapModel">
      <mapcomponent
        v-if="mapModel"
        :is="dynamicComponents.mapBox"
        :currentData="currentData"
        @back-location="dealMapData"
      ></mapcomponent>
    </Modal>

    <!-- <capture-config-modal
      v-model="captureConfigShow"
      :currentVideoObj="currentVideoObj"
    ></capture-config-modal> -->
  </div>
</template>

<script>
import CU from "@/common/util";
import mapBox from "@/components/searchMap";
import { Switch } from "view-design";
export default {
  name: "",
  components: {
    mapBox,
  },
  data() {
    return {
      table: {
        columns: [
          {
            title: "设备名称",
            minWith: 100,
            key: "name",
          },
          {
            title: "所在基地",
            key: "farmName",
          },
          {
            title: "设备码",
            key: "httpUrl",
          },
          // {
          //   title: "设备位置",
          //   minWith: 100,
          //   key: "address",
          // },
          // {
          //   title: "设备类型",
          //   minWith: 100,
          //   key: "deviceTypeName",
          // },
          // {
          //   title: "IP/域名",
          //   minWith: 100,
          //   tooltip: true,
          //   key: "linkIp",
          // },
          // {
          //   title: "端口号",
          //   width: 90,
          //   render: (h, { row }) => {
          //     return <span>{row.devicePort ? row.devicePort : "--"}</span>;
          //   },
          // },
          // {
          //   title: "通道号",
          //   width: 90,
          //   render: (h, { row }) => {
          //     return <span>{row.channelNo ? row.channelNo : "--"}</span>;
          //   },
          // },
          // {
          //   title: "设备登录名",
          //   width: 120,
          //   render: (h, { row }) => {
          //     return <span>{row.loginUser ? row.loginUser : "--"}</span>;
          //   },
          // },
          // {
          //   title: "设备登录密码",
          //   width: 120,
          //   render: (h, { row }) => {
          //     return <span>{row.loginPwd ? row.loginPwd : "--"}</span>;
          //   },
          // },
          // {
          //   title: "抓拍分辨率",
          //   width: 140,
          //   render: (h, { row }) => {
          //     return (
          //       <span>{row.photoTypeName ? row.photoTypeName : "--"}</span>
          //     );
          //   },
          // },
          // {
          //   title: "抓拍质量",
          //   width: 100,
          //   render: (h, { row }) => {
          //     return (
          //       <span>
          //         {row.photoQualityName ? row.photoQualityName : "--"}
          //       </span>
          //     );
          //   },
          // },
          // {
          //   title: "监控设备状态",
          //   width: 120,
          //   render: (h, { row }) => {
          //     return (
          //       <Switch
          //         size="large"
          //         true-value={1}
          //         false-value={
          //           +row.status === 0 ? 0 : +row.status === 2 ? 2 : ""
          //         }
          //         loading={this.statusLoading}
          //         value={+row.status}
          //         disabled={
          //           +row.status == 1
          //             ? this.CA("aisleVideo_stop")
          //               ? false
          //               : true
          //             : this.CA("aisleVideo_open")
          //             ? false
          //             : true
          //         }
          //         onInput={(val) => this.statusChange(val, row.id)}
          //       >
          //         <span slot="open">启用</span>
          //         <span slot="close">停用</span>
          //       </Switch>
          //     );
          //   },
          // },
          {
            title: "操作",
            width: 110,
            render: (h, { row }) => {
              // <a
              //   class="iconfont"
              //   title="抓拍策略配置"
              //   style={{
              //     marginRight: "10px",
              //     color: row.deviceType == 6 ? "#999" : "#2d8cf0",
              //     cursor: row.deviceType == 6 ? "not-allowed" : "pointer",
              //   }}
              //   onClick={() => {
              //     if (row.deviceType == 6) return;
              //     this.captureStrategy(row);
              //   }}
              // >
              //   &#xe687;
              // </a>;
              return (
                <div>
                  {this.CA("aisleVideo_update") && (
                    <a
                      class="iconfont"
                      title="编辑"
                      style="margin-right: 10px"
                      onClick={() => this.edit(row)}
                    >
                      &#xe669;
                    </a>
                  )}
                  {this.CA("aisleVideo_delete") && (
                    <Poptip
                      confirm
                      transfer
                      title="确定删除吗?"
                      on-on-ok={() => this.del(row.id)}
                    >
                      <a class="iconfont" title="删除">
                        &#xe66a;
                      </a>
                    </Poptip>
                  )}
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },

      modal: {
        show: false,
        title: "",
        submitLoading: false,
      },
      statusLoading: false,

      form: {
        name: "",
        // area: "",
        // address: "",
        // longLat: "",
        // regionCode: "",
        // thirdLongLat: "",
        // device: "",
        // linkIp: "",
        // modelType: "",
        // devicePort: "",
        // channelNo: "",
        // loginUser: "",
        // loginPwd: "",
        // resolvingPower: "",
        // quality: "",
        // imgurl: "",
        // remark: "",
        id: "",
        httpUrl: "",
        farmId: "",
      },
      rules: {
        name: [{ required: true, message: "请填写设备名称" }],
        farmId: [{ required: true, message: "请选择基地场景" }],
        httpUrl: [{ required: true, message: "请填写设备码" }],
        // address: [{ required: true, message: "请选择设备位置" }],
        // device: [{ required: true, message: "请选择设备类型" }],
        // linkIp: [{ required: true, message: "请填写IP/域名" }],
        // modelType: [{ required: true, message: "请选择监控类型" }],
        // devicePort: [
        //   { required: true, message: "请填写端口号" },
        //   {
        //     validator(rule, value, callback) {
        //       if (/^[0-9]*$/.test(value)) {
        //         callback();
        //         return;
        //       }
        //       callback(rule.message);
        //     },
        //     message: "请填写正确的端口号",
        //   },
        // ],
        // channelNo: [
        //   { required: true, message: "请填写通道号" },
        //   {
        //     validator(rule, value, callback) {
        //       if (/^[0-9]*$/.test(value)) {
        //         callback();
        //         return;
        //       }
        //       callback(rule.message);
        //     },
        //     message: "请填写正确的通道号",
        //   },
        // ],
        // loginUser: [{ required: true, message: "请填写设备登录名" }],
        // loginPwd: [{ required: true, message: "请填写设备登录密码" }],
        // resolvingPower: [{ required: true, message: "请选择抓拍分辨率" }],
        // quality: [{ required: true, message: "请选择抓拍质量" }],
        // imgurl: [
        //   { required: true, message: "请上传视频封面", trigger: "blur" },
        // ],
      },

      farmScene: [],
      changeOnSelect: true,

      mapModel: false,
      // map异步组件
      dynamicComponents: {
        mapBox: "",
      },
      currentData: null,

      deviceTypeArr: [],
      photoTypeArr: [],
      qualityArr: [],

      search_data: {
        name: "",
      },

      captureConfigShow: false,
      currentVideoObj: {},
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  computed: {
    config() {
      let config = {
        filter: {
          add: {
            addBtnName: "添加视频配置",
            ca: "aisleVideo_add",
          },
          width: 220,
          filterBox: [
            {
              conditionName: "设备名称",
              component: "input",
              field: "name",
              defaultValue: "",
            },
            // {
            //   conditionName: "设备位置",
            //   component: "input",
            //   field: "address",
            //   defaultValue: "",
            // },
            // {
            //   conditionName: "设备类型",
            //   component: "select",
            //   field: "deviceType",
            //   defaultValue: "",
            //   data: this.deviceTypeArr,
            //   parameterField: "num",
            //   showField: "name",
            //   clearable: true,
            // },
          ],
        },
        page: { ...this.page },
      };
      return config;
    },
  },
  methods: {
    dealMapData(positionData) {
      // this.form.address = positionData.address;
      // this.form.longLat = positionData.lnglat;
      // this.form.regionCode = positionData.addressComponent.adcode;
      // this.form.thirdLongLat = positionData.thirdLongLat;
    },
    // 调出map组件
    mapModelShow() {
      // this.currentData = this.form.thirdLongLat;
      // this.mapModel = true;
      // this.dynamicComponents.mapBox = mapBox;
    },
    search(data) {
      this.search_data = data;
      this.page.pageNo = 1;
      this.getList();
    },
    add() {
      this.modal = {
        show: true,
        title: "添加视频配置",
        submitLoading: false,
      };
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getList();
    },
    pageSizeChange(pageSize) {
      this.page.pageSize = pageSize;
      this.getList();
    },
    getList() {
      if (!this.CA("aisleVideo_check")) return;
      this.table.loading = true;
      this.$post(this.$api.BUSSDEVICEVIDEO.LIST, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
        ...this.search_data,
      })
        .then((res) => {
          // console.log("=====>res", res.list);
          this.table.data = res.list;
          this.page.total = +res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },

    //基地场景
    getArea() {
      this.$post(this.$api.FAMRMING.FARMLIST).then((res) => {
        // console.log("=====>res111", res);
        // let str = JSON.stringify(res)
        //   .replace(/id/g, "value")
        //   .replace(/name/g, "label")
        //   .replace(/sceneList/g, "children");
        // let area = JSON.parse(str);
        // area.forEach((item) => {
        //   if (item.sceneList && item.sceneList.length > 0) {
        //     delete item.sceneList;
        //     item.loading = false;
        //     item.children = [];
        //   }
        // });
        this.farmScene = res.list;
      });
    },
    //cascader动态加载
    loadData(item, callback) {
      item.loading = true;
      this.$post(this.$api.PRODUCTION_AREA.SCENE_LIST, {
        farmId: item.value,
      })
        .then((res) => {
          item.children = CU.formatTreeData(res.list, "sceneId");
          if (this.changeOnSelect) {
            callback();
          }
        })
        .finally(() => {
          item.loading = false;
        });
    },
    //cascader改变
    cascaderChange(value, selectedData) {
      // console.log("====>vakue", value);
      if (value.length === 1) {
        this.farmId = value[0];
        return;
      }
      if (value.length === 2) {
        this.farmId = value[0];
        this.sceneId = value[1];
        return;
      }
      if (value.length === 3) {
        this.farmId = value[0];
        this.sceneId = value[1];
        this.areaId = value[2];
        return;
      }
      if (value.length === 0 && !this.changeOnSelect) {
        this.changeOnSelect = true;
      }
    },

    getDeviceType() {
      this.$post(this.$api.DICT.SYS_LIST, {
        name: "设备厂商",
      }).then((res) => {
        this.deviceTypeArr = res;
        console.log(this.deviceTypeArr);
      });
    },

    getPhotoType() {
      this.$post(this.$api.DICT.SYS_LIST, {
        name: "抓拍图片分辨率",
      }).then((res) => {
        this.photoTypeArr = res;
      });
    },
    getPhotoQuality() {
      this.$post(this.$api.DICT.SYS_LIST, {
        name: "抓拍图片质量",
      }).then((res) => {
        this.qualityArr = res;
      });
    },

    statusChange(status, id) {
      this.$post(
        status == "1"
          ? this.$api.BUSSDEVICEVIDEO.STOP
          : this.$api.BUSSDEVICEVIDEO.OPEN,
        {
          id,
        }
      ).then(() => {
        this.$Message.success(status == "1" ? "启用成功" : "停用成功");
        this.getList();
      });
    },

    captureStrategy({ name, id, farmId, sceneId }) {
      this.currentVideoObj = { name, id, farmId, sceneId };
      this.captureConfigShow = true;
    },

    edit(row) {
      for (let key in this.form) {
        this.form[key] = row[key];
      }
      this.form.id = row.id;
      // this.form.area = row.farmId
      this.form.farmId = row.farmId;
      // this.form.device = `${row.deviceType}/${row.deviceTypeName}`;
      // if (row.deviceType != 6) {
      //   this.form.resolvingPower = `${row.photoType}/${row.photoTypeName}`;
      //   this.form.quality = `${row.photoQuality}/${row.photoQualityName}`;
      // }
      // this.form.imgurl = row.servicePath + row.imgurl;
      // let wgs84 = JSON.parse(row.thirdLongLat);
      // let wgs84 = row.thirdLongLat.split(",");
      // this.currentData = {
      //   lng: wgs84[0],
      //   lat: wgs84[1],
      // };
      this.modal = {
        show: true,
        title: "编辑视频配置",
        submitLoading: false,
      };
    },

    del(id) {
      this.$post(this.$api.BUSSDEVICEVIDEO.DELETE, {
        id,
      }).then(() => {
        this.$Message.success("删除成功");
        this.getList();
      });
    },

    modalShow(visible) {
      if (visible) return;
      this.form = {
        id: "",
        name: "",
        // area: [],
        farmId: "",
        httpUrl: "",
        // address: "",
        // longLat: "",
        // regionCode: "",
        // thirdLongLat: "",
        // device: "",
        // linkIp: "",
        // modelType: "",
        // devicePort: "",
        // channelNo: "",
        // loginUser: "",
        // loginPwd: "",
        // resolvingPower: "",
        // quality: "",
        // imgurl: "",
        // remark: "",
      };
      this.currentData = null;
      this.$refs.form.resetFields();
      // this.$refs.upload.clearFiles();
    },
    submit() {
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        let params = { ...this.form };
        // params.farmId = this.form.area[0];
        // params.sceneId = this.form.area[1];
        // params.deviceType = this.form.device.split("/")[0];
        // params.deviceTypeName = this.form.device.split("/")[1];
        // if (params.deviceType != 6) {
        //   params.photoType = this.form.resolvingPower.split("/")[0];
        //   params.photoTypeName = this.form.resolvingPower.split("/")[1];
        //   params.photoQuality = this.form.quality.split("/")[0];
        //   params.photoQualityName = this.form.quality.split("/")[1];
        // }
        // params.imgurl = this.form.imgurl.replace(
        //   /http:\/\/img.xd.sidwit.com\//g,
        //   ""
        // );
        // delete params.area;
        // delete params.device;
        // delete params.resolvingPower;
        // delete params.quality;
        this.modal.submitLoading = true;
        this.$post(
          params.id
            ? this.$api.BUSSDEVICEVIDEO.UPDATE
            : this.$api.BUSSDEVICEVIDEO.ADD,
          params
        )
          .then(() => {
            this.getList();
            this.modal.show = false;
          })
          .finally(() => {
            this.modal.submitLoading = false;
          });
      });
    },
  },
  mounted() {
    this.getList();
    this.getArea();
    this.getDeviceType();
    this.getPhotoType();
    this.getPhotoQuality();
  },
};
</script>

<style lang="less" scoped>
.aisle-video-page {
  width: 100%;
  height: 100%;
  .table-header {
    display: flex;
    flex-wrap: nowrap;
    padding: 16px;
    .search-pane {
      display: flex;
      flex-wrap: wrap;
      flex-shrink: 1;
      flex-grow: 1;
      span {
        padding-right: 16px;
      }
    }
  }
  .footer {
    text-align: right;
    padding: 16px;
  }
}
</style>
